import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image'

// Components
import { BannerAlt } from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Points from 'components/Points'
import RequestOffer from 'components/RequestOffer'
import ParseContent from 'components/ParseContent'

import 'styles/templates/PageTemplate.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      acf {
        title: banner_title
        image: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 1024, toFormat: JPG) {
                src
              }
            }
          }
        }

        company {
            logo {
              localFile {
                publicURL
              }
            }
            description
        }

      }
      
      description: content

      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: {
      path,
      acf: { title, image, company },
      description,
      yoastMeta,
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} image={image.localFile.childImageSharp.fixed.src} article />
      <div className="page-template goede-doelen">
        <BannerAlt>
          <BackgroundImage
            className="banner-background-image"
            fluid={image.localFile.childImageSharp.fluid}
          />
          <div className="banner-content row pt-5">
            <div className="col-lg-7 pt-5">
              <h1 className="mt-5">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </h1>
            </div>
            <div className="col-lg-6" />
          </div>
        </BannerAlt>

        {company && (
          <section>
           <div className="container font-family-secondary font-size-sm font-family-secondary">
             <div className="row py-5">
               {company.map(({ logo, description }, index) => (
                 <div className="col-lg-6 mb-4" key={index}>
                   <img src={logo.localFile.publicURL} width="200" className="mb-3"/>
                   <ParseContent content={description} />
                 </div>
               ))}
             </div>
           </div>
         </section>
        )}

        {description && (
          <div className="page-container container mt-5 font-family-secondary text-justify font-size-sm font-family-secondary">
            <ParseContent content={description} />
          </div>
        )}

        <div className="container mt-lg-5 pt-lg-5">
          <Points />
        </div>

        <div className="mt-5 pt-5">
          <RequestOffer isRed />
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate
